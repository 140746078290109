import "../bootstrap";
import Swal from "sweetalert2";
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import Masonry from "masonry-layout";
import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";

try {
    Swiper.use([Navigation, Pagination, Autoplay]);

    window.Swiper = Swiper;
    window.Swal = Swal;
    window.Masonry = Masonry;
    window.lightGallery = lightGallery;
    window.lgThumbnail = lgThumbnail;
    window.lgZoom = lgZoom;
    window.lgVideo = lgVideo;

    require("owl.carousel");
} catch (err) {
    console.log(err);
}
